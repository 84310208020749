import {
  FEED_PAGE_SECTIONS,
  SECTION_POST_LIST,
  getLayoutContentHeightTypeParam,
  PG_LAYOUTS_WITH_CONTENT_HEIGHT,
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS,
  getLayoutTypeById,
} from '@wix/communities-blog-client-common';
import { BLOG_WIDGET_ID, POST_LIST_WIDGET_ID } from '../../constants/widgets';
import { blogAppDefId } from '../../constants/apps';
import { createUpdateStyleParams } from './create-update-style-params';

const layoutNames = PG_LAYOUTS_WITH_CONTENT_HEIGHT.map(getLayoutTypeById);
export const generateStylesReducer = (acc, section) => {
  return [
    ...acc,
    ...layoutNames.map((layoutName) => {
      return getLayoutContentHeightTypeParam(section, layoutName);
    }),
  ];
};
const feedStyleKeys = FEED_PAGE_SECTIONS.reduce(generateStylesReducer, []);
const postListStyleKeys = [SECTION_POST_LIST].reduce(generateStylesReducer, []);

export const updateFeedContentHeightTypeStyles = createUpdateStyleParams(
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO,
  'number',
  feedStyleKeys,
);
export const updatePostListContentHeightTypeStyles = createUpdateStyleParams(
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO,
  'number',
  postListStyleKeys,
);

const changeFeedContentHeightType = async ({ sdk, appToken, blogAppComponents }) => {
  const component = blogAppComponents.find(({ widgetId }) => [BLOG_WIDGET_ID].includes(widgetId));
  const compRef = await sdk.document.components.getById(appToken, {
    id: component.id,
  });
  await updateFeedContentHeightTypeStyles(sdk, appToken, compRef);
};

const changePostListContentHeightType = async ({ sdk, appToken, blogAppComponents, newWidgetCompRef }) => {
  const component = blogAppComponents.find((comp) => comp.id === newWidgetCompRef.id);
  const newWidgetIsPostList = component?.widgetId === POST_LIST_WIDGET_ID;

  if (!newWidgetIsPostList) {
    return;
  }
  await updatePostListContentHeightTypeStyles(sdk, appToken, newWidgetCompRef);
};

export const setDefaultContentHeightType = async ({ sdk, appToken }, newWidgetCompRef) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  if (!blogAppData) {
    return;
  }
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);

  if (!newWidgetCompRef) {
    await changeFeedContentHeightType({ sdk, appToken, blogAppComponents });
  } else {
    await changePostListContentHeightType({ sdk, appToken, blogAppComponents, newWidgetCompRef });
  }
};
