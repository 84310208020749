import { get } from 'lodash';

const base64DecodeUnicode = (base64String) =>
  decodeURIComponent(
    atob(base64String)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

export const parseInstanceWithoutValidation = (instance) => {
  const [, data] = instance.split('.');
  return JSON.parse(base64DecodeUnicode(data));
};

export const getSiteMemberId = async (sdk, appToken) => {
  try {
    const encodedInstance = await sdk.document.info.getAppInstance(appToken);
    const instance = parseInstanceWithoutValidation(encodedInstance);
    return get(instance, 'siteMemberId', '');
  } catch (_) {
    return '';
  }
};

export const getMetaSiteId = (instance) => {
  try {
    const instanceObject = parseInstanceWithoutValidation(instance);
    return get(instanceObject, 'metaSiteId');
  } catch (_) {
    return;
  }
};
