import { get } from 'lodash';

const createNumberParamFull = (param) => `param_number_${param}`;
const createBooleanParamFull = (param) => `param_boolean_${param}`;

export const getStyleProperty = (styles, property) => get(styles, `style.properties.${property}`);

export const CATEGORY_LAYOUT_TYPE_STYLE_PARAM = 'layout-category-layoutType';
export const HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM = 'layout-homepage-layoutType';

export const AUTHOR_NAME_STYLE_ENABLED_PARAM = 'blog-isAuthorNameEnabled';
export const MORE_OPTIONS_STYLE_ENABLED_PARAM = 'blog-isMoreOptionsMenuEnabled';
export const POST_PUBLISH_DATE_ENABLED_STYLE_PARAM = 'blog-isPostPublishDateEnabled';
export const IS_READING_TIME_ENABLED_PARAM = 'blog-isReadingTimeEnabled';
export const IS_VIEW_COUNT_ENABLED_PARAM = 'blog-isViewCountEnabled';
export const IS_LIKE_COUNT_ENABLED_STYLE_PARAM = 'blog-isLikeCountEnabled';
export const IS_COMMENT_COUNT_ENABLED_PARAM = 'blog-isCommentCountEnabled';
export const IS_POST_DESCRIPTION_ENABLED_PARAM = 'blog-isPostDescriptionEnabled';

export const HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL = createNumberParamFull(HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM);
export const CATEGORY_LAYOUT_TYPE_STYLE_PARAM_FULL = createNumberParamFull(CATEGORY_LAYOUT_TYPE_STYLE_PARAM);

export const AUTHOR_NAME_STYLE_ENABLED_PARAM_FULL = createBooleanParamFull(AUTHOR_NAME_STYLE_ENABLED_PARAM);
export const MORE_OPTIONS_STYLE_ENABLED_PARAM_FULL = createBooleanParamFull(MORE_OPTIONS_STYLE_ENABLED_PARAM);
export const POST_PUBLISH_DATE_ENABLED_STYLE_PARAM_FULL = createBooleanParamFull(POST_PUBLISH_DATE_ENABLED_STYLE_PARAM);
export const IS_READING_TIME_ENABLED_PARAM_FULL = createBooleanParamFull(IS_READING_TIME_ENABLED_PARAM);
export const IS_VIEW_COUNT_ENABLED_PARAM_FULL = createBooleanParamFull(IS_VIEW_COUNT_ENABLED_PARAM);
export const IS_LIKE_COUNT_ENABLED_STYLE_PARAM_FULL = createBooleanParamFull(IS_LIKE_COUNT_ENABLED_STYLE_PARAM);
export const IS_COMMENT_COUNT_ENABLED_PARAM_FULL = createBooleanParamFull(IS_COMMENT_COUNT_ENABLED_PARAM);
export const IS_POST_DESCRIPTION_ENABLED_PARAM_FULL = createBooleanParamFull(IS_POST_DESCRIPTION_ENABLED_PARAM);
