import { get, isUndefined } from 'lodash';

export const createUpdateStyleParams = (value, type, styleKeys) => async (sdk, appToken, compRef) => {
  const styleParams = await sdk.document.tpa.getStyleParams(appToken, {
    compRef,
  });
  const changedStyleParams = styleKeys.reduce((acc, styleKey) => {
    if (isUndefined(get(styleParams, `numbers.${styleKey}`))) {
      acc.push({
        type,
        key: styleKey,
        param: { value },
      });
    }
    return acc;
  }, []);
  if (changedStyleParams.length > 0) {
    await sdk.document.tpa.setStyleParams(appToken, {
      compRef,
      styleParams: changedStyleParams,
    });
  }
};
