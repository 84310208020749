import { get, noop } from 'lodash';
export var timeoutPromise = function (promise, ms, errMsg, abortSignal) {
    if (abortSignal === void 0) { abortSignal = {}; }
    var abort = get(promise, 'abort', noop);
    promise = get(promise, 'ready', promise);
    if (abortSignal.aborted) {
        abort();
    }
    else if (abortSignal.addEventListener) {
        abortSignal.addEventListener('abort', function () {
            abort();
        });
    }
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            abort();
            var error = new Error(errMsg);
            error.name = 'TimeoutError';
            reject(error);
        }, ms);
        promise.then(resolve, reject);
    });
};
