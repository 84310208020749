import { get } from 'lodash';
/* This is a small abstraction that allows emitting events from settings to OOI app.
   For example we can use it for things like navigating preview when user is walking
   to different tabs in app settings.
*/
export var SETTINGS_EVENT_ID = '__settings-event__';
var eventsTsCache = [];
export function createSettingsEvent(payload) {
    return [
        SETTINGS_EVENT_ID,
        {
            ts: Date.now(),
            payload: payload,
        },
        { scope: 'COMPONENT' },
    ];
}
export function getSettingsEvent(publicData) {
    var settingsEvent = get(publicData, ['COMPONENT', SETTINGS_EVENT_ID]);
    if (!settingsEvent) {
        return null;
    }
    // since this data is persisted we want to consider this event relevant only if it was emitted
    // during this session, so we check event (ts) timestamp.
    var isRecent = Date.now() - settingsEvent.ts <= 3000;
    // there can be no new event within isRecent time frame, but there can be other settings change
    // so in order to not reapply same event twice we look at the cache
    var isAlreadyPrecessed = eventsTsCache.includes(settingsEvent.ts);
    if (!isRecent && isAlreadyPrecessed) {
        return null;
    }
    eventsTsCache.push(settingsEvent.ts);
    eventsTsCache = eventsTsCache.slice(-10);
    return settingsEvent.payload;
}
