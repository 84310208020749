import { get } from 'lodash';

export const getAssetsUrl = (options) => {
  const editorScriptUrl = get(options, 'initialAppData.editorScriptUrl', '');
  return `${
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:33666'
      : editorScriptUrl.substring(0, editorScriptUrl.lastIndexOf('/'))
  }/assets`;
};
