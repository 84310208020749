export const SITE_WAS_PUBLISHED = 'siteWasPublished';
export const SITE_WAS_SAVED = 'siteWasSaved';
export const MANAGE_POSTS_EVENT = 'manage-posts';
export const DELETE_BLOG_EVENT = 'delete-blog';
export const APP_ACTION_EVENT = 'appActionClicked';
export const COMPONENT_ADDED_TO_STAGE = 'componentAddedToStage';
export const COMPONENT_DATA_CHANGED = 'componentDataChanged';
export const EDITOR_ACTION_TYPE = 'editorActions';
export const APP_ACTION_EVENTS = {
  CREATE_POST: 'createPost',
  MANAGE_POSTS: 'managePosts',
  OPEN_BLOG_PAGES_PANEL: 'openPagesPanelOnBlog',
  OPEN_POST_PAGE_SETTINGS: 'openPostPageSettings',
  OPEN_BLOG_ADD_PANEL: 'openBlogAddPanel',
};
export const INSTANCE_CHANGED = 'instanceChanged';
