import { get, find, isEmpty, isUndefined, isObject, identity, isFunction } from 'lodash';
import { FEED_PAGE_SECTIONS } from '@wix/communities-blog-client-common';
import { blogAppDefId } from '../../constants/apps';
import FontBuilder from './font-builder';
import defaultStyles from './default-styles';
import { THEME_FONT_KEY_MAP } from './theme';
import { trueToFalse, rgbToHex } from './utils';

const MAX_TEXT_LINE_COUNT = 3;
const CARD_TOP_BOTTOM_PADDING = 35;
const TITLE_BOTOM_MARGIN = 10;
const DEFAULT_LINE_HEIGHT = 1.4;

export default class BlogWidgetBuilder {
  constructor(oldBlogComponent, props) {
    const { widgetId, applicationId, fontMap, styleMap, widgetType = BlogWidgetBuilder.TPAWidget } = props;
    this.props = props;
    this.widgetId = widgetId;
    this.applicationId = applicationId;
    this.oldBlogComponent = oldBlogComponent;
    this.layout = { ...oldBlogComponent.layout };
    this.mobileLayout = get(oldBlogComponent, 'mobileStructure.layout');
    this.isHiddenOnMobile = Boolean(get(oldBlogComponent, 'mobileHints.hidden'));
    this.isCustomStyle = false;
    this.tpaData = {};
    this.properties = {};
    this.propertiesSource = {};
    this.fontMap = fontMap;
    this.styleMap = styleMap;
    this.widgetType = widgetType;
  }

  static TPAMultisection = 'TPAMultiSection';
  static TPASection = 'TPASection';
  static TPAWidget = 'TPAWidget';

  widgetDataType = {
    [BlogWidgetBuilder.TPAMultisection]: 'TPAMultiSection',
    [BlogWidgetBuilder.TPASection]: 'TPA',
    [BlogWidgetBuilder.TPAWidget]: 'TPAWidget',
  };

  widgetSkin = {
    [BlogWidgetBuilder.TPAMultisection]: 'TPASectionSkin',
    [BlogWidgetBuilder.TPASection]: 'TPASectionSkin',
    [BlogWidgetBuilder.TPAWidget]: 'TPAWidgetSkin',
  };

  widgetStyle = {
    [BlogWidgetBuilder.TPAMultisection]: 'tpas0',
    [BlogWidgetBuilder.TPASection]: 'tpas0',
    [BlogWidgetBuilder.TPAWidget]: 'tpaw0',
  };

  setDimensions({ width, height } = {}) {
    isUndefined(width) || (this.layout.width = width);
    isUndefined(height) || (this.layout.height = height);
    return this;
  }

  setCoordinates({ x, y } = {}) {
    isUndefined(x) || (this.layout.x = x);
    isUndefined(y) || (this.layout.y = y);
    return this;
  }

  setTpaData(key, value) {
    if (isUndefined(value)) {
      return this;
    }
    this.tpaData[key] = value;
    return this;
  }

  mapValueParam(from, to, options = {}) {
    const value = this.resolveValue(from, options);
    return this.setValueParam(to, value);
  }

  parseDescriptor(from) {
    if (isObject(from)) {
      Object.keys(from).forEach((key) => (from[key] = this.interpolate(from[key])));
    } else {
      const [view, fieldId, key] = this.interpolate(from).split('/');
      from = { view, fieldId, key };
    }

    return from;
  }

  resolveValue(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }

    from = this.parseDescriptor(from);
    const value = get(this.findParam(from), 'value');
    return this.formatValue(value, options);
  }

  formatValue(value, { defaultValue, valueMap, minValue, maxValue, formatter = identity } = {}) {
    if (isUndefined(value)) {
      return defaultValue;
    }
    value = formatter(valueMap ? valueMap[value] || defaultValue : value);
    if (maxValue) {
      value = Math.min(maxValue, value);
    }
    if (minValue) {
      value = Math.max(minValue, value);
    }
    return value;
  }

  resolveThemeValue(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    const [view, fieldId, key, prop] = this.interpolate(from).split('/');
    const param = this.findParam({ view, fieldId, key }) || find(defaultStyles, { view, fieldId, key }) || {};
    const style = get(this.styleMap, param.value);
    const value = get(style, `style.properties.${prop}`);
    return this.formatValue(value, options);
  }

  resolveThemeFont(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    from = this.parseDescriptor(from);
    const param = this.findParam(from) || find(defaultStyles, from) || {};
    const editorKey = get(THEME_FONT_KEY_MAP, param.value);
    const font = get(this.fontMap, editorKey);
    return { font, editorKey };
  }

  mapLogicParam(from, to, options) {
    return this.mapPath(`data.appLogicParams.${from}.value`, to, options);
  }

  mapViewName(to, options) {
    return this.mapPath('data.viewName', to, options);
  }

  mapPath(from, to, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    const value = get(this.oldBlogComponent, from);
    return this.setValueParam(to, this.formatValue(value, options));
  }

  shouldSkip({ when }) {
    if (!when) {
      return false;
    }
    const [path, expected] = when;
    let value = this.resolveValue(path);
    if (isUndefined(value)) {
      value = get(this.oldBlogComponent, path);
    }
    return value !== expected;
  }

  findParam(props) {
    return find(
      get(this.oldBlogComponent, 'data.appLogicCustomizations', []).filter((x) => x.format !== 'Mobile'),
      props,
    );
  }

  mapFont(to) {
    return new FontBuilder(this, to);
  }

  resolveMaxTextHeight(from, fontSize, fallbackFontSize, options = {}) {
    const { font } = this.resolveThemeFont(from, options) || {};
    const lineHeight =
      !isUndefined(font) && font.lineHeight ? Number(font.lineHeight.replace('em', '')) : DEFAULT_LINE_HEIGHT;

    if (isUndefined(fontSize)) {
      fontSize = !isUndefined(font) && font.size ? Number(font.size.replace('px', '')) : fallbackFontSize;
    }

    return Math.ceil(fontSize * MAX_TEXT_LINE_COUNT * lineHeight);
  }

  mapContentHeight(to, showDescription = true) {
    let contentHeight = CARD_TOP_BOTTOM_PADDING;
    const visiblityOptions = {
      defaultValue: true,
      formatter: trueToFalse,
    };

    const titleVisible = this.resolveValue('$(viewName)/title/comp.hidden', visiblityOptions);
    if (titleVisible) {
      const titleFontSize = this.resolveValue('$(viewName)/title/comp.fontSize', {
        minValue: 6,
        maxValue: 215,
        formatter: Number,
      });
      const titleMaxHeight = this.resolveMaxTextHeight('$(viewName)/title/comp.style', titleFontSize, 28);
      contentHeight += titleMaxHeight + TITLE_BOTOM_MARGIN;
    }
    const descriptionVisible = showDescription && this.resolveValue('$(viewName)/text/comp.hidden', visiblityOptions);
    if (descriptionVisible) {
      const descriptionFontSize = this.resolveValue('$(viewName)/text/comp.fontSize', {
        minValue: 6,
        maxValue: 18,
        formatter: Number,
      });
      const descriptionMaxHeight = this.resolveMaxTextHeight('$(viewName)/text/comp.style', descriptionFontSize, 16);
      contentHeight += descriptionMaxHeight;
    }

    return this.setValueParam(to, contentHeight);
  }

  mapThemeColor(from, to, options = {}) {
    let value = this.resolveThemeValue(from, options);

    if (isUndefined(value)) {
      return this;
    }
    const path = from.split('/');
    path[path.length - 1] = `alpha-${path[path.length - 1]}`;
    const defaultOpacityValue = get(options, ['defaultValue', 1]);
    const opacity = this.resolveThemeValue(path.join('/'), {
      formatter: Number,
      ...options,
      defaultValue: defaultOpacityValue,
    });
    const isRGB = value && typeof value === 'string' && value.indexOf('#') === -1 && value.split(',').length > 2;
    if (isRGB) {
      value = rgbToHex(value);
    }
    return this.setColor(to, value, options.opacity || opacity);
  }

  mapThemeParam(from, to, options = {}) {
    const value = this.resolveThemeValue(from, options);
    if (!isUndefined(value)) {
      this.setValueParam(to, value);
    }
    return this;
  }

  mapColor(from, to, options = {}) {
    const value = this.resolveValue(from, options);
    if (isUndefined(value)) {
      return this;
    }
    return this.setColor(to, value, options.opacity);
  }

  mapFontColor(from, to, options = {}) {
    const { font } = this.resolveThemeFont(from.replace('$(key)', 'comp.style'), options) || {};
    if (font && !isUndefined(font.color)) {
      const value = font.color.replace(/({|})/g, '');
      this.setColor(to, value, 1);
    }
    return this.mapColor(from.replace('$(key)', 'comp.color'), to, options);
  }

  setColor(to, value, opacity) {
    if (Array.isArray(value)) {
      [value, opacity] = value;
    }

    if (/color_/.test(value)) {
      this.setThemeParam(to, value.replace('back', ''));
    } else {
      this.setValueParam(to, value);
    }

    return this.setValueParam(`alpha-${to}`, opacity);
  }

  setValueParam(styleParamName, value) {
    if (isUndefined(value)) {
      return this;
    }
    return this.setParam(styleParamName, value, 'value');
  }

  setThemeParam(styleParamName, value) {
    return this.setParam(styleParamName, value, 'theme');
  }

  setParam(styleParamName, value, source) {
    const params = styleParamName.includes('$(section)')
      ? FEED_PAGE_SECTIONS.map((section) => styleParamName.replace('$(section)', section))
      : [styleParamName];

    params.forEach((paramName) => {
      this.properties[paramName] = value;
      this.propertiesSource[paramName] = source;
      if (/^param_color_/.test(paramName)) {
        this.propertiesSource[`alpha-${paramName}`] = 'value';
      }
    });

    return this;
  }

  interpolate(str) {
    return str.replace(/\$\(viewName\)/g, get(this.oldBlogComponent, 'data.viewName', ''));
  }

  when(path, expected) {
    const value = this.resolveValue(path) || get(this.oldBlogComponent, path);
    const branch = new BlogWidgetBuilder(this.oldBlogComponent, this.props);
    branch.parent = this;
    branch.shouldMergeParent = this.evaluateExpected(value, expected);
    return branch;
  }

  evaluateExpected(value, expected) {
    if (isFunction(expected)) {
      return expected(value);
    }
    const expectedAsArray = Array.isArray(expected) ? expected : [expected];
    return expectedAsArray.includes(value);
  }

  value() {
    if (!this.parent) {
      throw new Error('Invalid operation');
    }
    if (this.shouldMergeParent) {
      Object.assign(this.parent.properties, { ...this.properties });
      Object.assign(this.parent.propertiesSource, { ...this.propertiesSource });
      Object.assign(this.parent.tpaData, { ...this.tpaData });
    }
    return this.parent;
  }

  build() {
    const widgetType = this.widgetType;
    const widgetSkin = this.widgetSkin[widgetType];
    const widgetDataType = this.widgetDataType[widgetType];
    this.style = this.widgetStyle[widgetType];

    if (!isEmpty(this.properties)) {
      this.style = {
        type: 'ComponentStyle',
        styleType: 'custom',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: this.properties,
          propertiesSource: this.propertiesSource,
          groups: {},
        },
        componentClassName: `wysiwyg.viewer.components.tpapps.${widgetType}`,
        skin: `wysiwyg.viewer.skins.${widgetSkin}`,
      };
    }

    const componentDefinition = {
      type: 'Component',
      skin: `wysiwyg.viewer.skins.${widgetSkin}`,
      componentType: `wysiwyg.viewer.components.tpapps.${widgetType}`,
      layout: this.layout,
      data: {
        widgetId: this.widgetId,
        applicationId: this.applicationId,
        appDefinitionId: blogAppDefId,
        type: widgetDataType,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      },
      mobileHints: {
        type: 'MobileHints',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      },
      style: this.style,
    };

    if (!isEmpty(this.tpaData)) {
      componentDefinition.data.tpaData = {
        type: 'TPAData',
        content: JSON.stringify(this.tpaData),
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      };
    }

    if (!this.isHiddenOnMobile && this.mobileLayout) {
      componentDefinition.mobileStructure = { layout: this.mobileLayout };
      componentDefinition.mobileHints.hidden = false;
    }

    return componentDefinition;
  }
}
