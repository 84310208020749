import { pick, get } from 'lodash';
import { blogAppDefId } from '../../constants/apps';
import { getSerializedComponents, getComponentRef, getIndexRef } from './sdk-utils';
import mapComponent from './component-mapper';
import {
  TOKEN,
  MIGRATION_STATUS_NOT_STARTED,
  MIGRATION_STATUS_STARTED,
  MIGRATION_STATUS_FINISHED,
  OLD_BLOG_FEED_PAGE_ID,
  OLD_BLOG_POST_PAGE_ID,
  OLD_BLOG_APP_PART,
  OLD_BLOG_THANK_YOU_PAGE_ID,
} from './constants';
import { POST_WIDGET_ID, BLOG_WIDGET_ID } from '../../constants/widgets';
import { patchSettings } from '../settings';
import { normalizeColorThemeName, getTextPresets, getColorPresets } from './style-utils';
import { MIGRATION_STEPS } from '../../constants/magic-migration-steps';

class MagicMigration {
  status = MIGRATION_STATUS_NOT_STARTED;

  constructor(context, options = {}) {
    this.context = context;
    this.options = options;
    this.currentStep = '';
  }

  getStep() {
    return this.currentStep;
  }

  async run(options = {}) {
    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_CAN_MIGRATE;
    if (!(await this.canMigrate())) {
      return;
    }

    // Removing since api is removed from editor
    // this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_DISABLE_AUTOSAVE;
    // if (!(await this.disableAutoSave())) {
    //   return;
    // }

    this.status = MIGRATION_STATUS_STARTED;
    await this.convertOldBlogPages();
    await this.replaceOldBlogWidgets();
    await this.removeNewBlogPages();
    await this.saveStyleParams();
    await this.convertOldBlogThankYouPage();

    if (options.isOffline) {
      await this.loadBlogPages();
    }

    this.status = MIGRATION_STATUS_FINISHED;
    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_FINISHED;
  }

  async loadBlogPages() {
    const currentPage = this.context.sdk.document.pages.getCurrent();
    if (currentPage.id !== this.oldBlogFeedPage.id) {
      await this.context.sdk.document.pages.navigateTo(TOKEN, { pageRef: this.oldBlogFeedPage });
    }

    await this.context.sdk.document.pages.navigateTo(TOKEN, { pageRef: this.oldBlogPostPage });
  }

  async disableAutoSave() {
    return (await this.context.sdk.document.initAutosave({ enabled: false })) === false;
  }

  async canMigrate() {
    this.pages = await this.context.sdk.pages.data.getAll();

    this.pages.forEach((page) => {
      if (page.type === 'AppPage' && page.appPageId === OLD_BLOG_FEED_PAGE_ID) {
        this.oldBlogFeedPageRef = { id: page.id, type: 'DESKTOP' };
        this.oldBlogFeedPage = page;
      } else if (page.type === 'AppPage' && page.appPageId === OLD_BLOG_POST_PAGE_ID) {
        this.oldBlogPostPageRef = { id: page.id, type: 'DESKTOP' };
        this.oldBlogPostPage = page;
      } else if (page.type === 'Page' && page.managingAppDefId === blogAppDefId && page.tpaPageId === 'blog') {
        this.newBlogFeedPage = page;
      } else if (page.type === 'Page' && page.managingAppDefId === blogAppDefId && page.tpaPageId === 'post') {
        this.newBlogPostPage = page;
      }
    });

    return this.oldBlogFeedPage && this.newBlogFeedPage && this.newBlogPostPage;
  }

  async convertOldBlogPages() {
    const { sdk } = this.context;

    if (this.options.dryRun) {
      return;
    }

    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_OLD_BLOG_FEED_CONVERSION;
    await sdk.document.wixapps.convertAppPage(TOKEN, { pageRef: this.oldBlogFeedPageRef });
    await sdk.pages.data.update(TOKEN, {
      pageRef: this.oldBlogFeedPageRef,
      data: {
        ...this.oldBlogFeedPage,
        ...pick(this.newBlogFeedPage, ['type', 'managingAppDefId', 'tpaApplicationId', 'tpaPageId']),
        appPageId: null,
        appPageType: null,
        appInnerID: null,
      },
    });
    await sdk.pages.data.update(TOKEN, {
      pageRef: this.newBlogFeedPage,
      data: {
        ...this.newBlogFeedPage,
        tpaPageId: '',
        tpaApplicationId: 0,
      },
    });

    if (this.oldBlogPostPage) {
      this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_OLD_BLOG_POST_PAGE_CONVERSION;
      await sdk.document.wixapps.convertAppPage(TOKEN, { pageRef: this.oldBlogPostPageRef });
      await sdk.pages.data.update(TOKEN, {
        pageRef: this.oldBlogPostPageRef,
        data: {
          ...this.oldBlogPostPage,
          ...pick(this.newBlogPostPage, ['type', 'managingAppDefId', 'tpaApplicationId', 'tpaPageId', 'title']),
          appPageId: null,
          appPageType: null,
          appInnerID: null,
        },
      });
      await sdk.pages.data.update(TOKEN, {
        pageRef: this.newBlogPostPage,
        data: {
          ...this.newBlogPostPage,
          tpaPageId: '',
          tpaApplicationId: 0,
        },
      });
    }
  }

  isOldBlogComponent(id) {
    const OLD_BLOG_APP_PART_IDS = Object.values(OLD_BLOG_APP_PART);
    return OLD_BLOG_APP_PART_IDS.includes(id);
  }

  async replaceOldBlogWidgets() {
    const { sdk } = this.context;

    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_COMPONENTS_REPLACE;
    const oldBlogComponents = await getSerializedComponents({
      sdk,
      predicate: ({ type }) => /(AppPart|RSSButton)/.test(type),
    });

    const isOldPostPageWidgetMissing = !oldBlogComponents.find(
      (component) => get(component, 'serialized.data.appPartName') === OLD_BLOG_APP_PART.SINGLE_POST,
    );
    if (isOldPostPageWidgetMissing && this.oldBlogPostPageRef) {
      await this.addNewBlogPostWidgetToPostPage(sdk);
    }

    const componentsToReplace = [];
    await Promise.all(
      oldBlogComponents.map(async ({ componentRef, containerRef, pageRef, serialized }) => {
        const type = get(serialized, 'data.appPartName') || get(serialized, 'data.type');
        this.currentStep = `${MIGRATION_STEPS.CLIENT_MIGRATION_COMPONENTS_REPLACE} -- ${type}`;

        const component = await mapComponent(serialized, type, { sdk, oldBlogComponents, dryRun: this.options.dryRun });
        if (!component) {
          return;
        }

        if (!this.isOldBlogComponent(serialized.data.appPartName)) {
          return;
        }

        const widgetId = get(component, 'componentDefinition.data.widgetId');
        if (this.options.whiteList && !this.options.whiteList.includes(widgetId)) {
          return;
        }

        const isSectionWidget = [POST_WIDGET_ID, BLOG_WIDGET_ID].includes(widgetId);
        component.containerRef = isSectionWidget ? pageRef : containerRef;
        component.indexRef = await (isSectionWidget ? getIndexRef(sdk, componentRef) : componentRef);
        componentsToReplace.push(component);
      }),
    );

    for (const { componentDefinition, props, containerRef, indexRef } of componentsToReplace) {
      const componentIndex = await sdk.components.arrangement.getIndex(TOKEN, { componentRef: indexRef });
      await sdk.components.addAndAdjustLayout(TOKEN, {
        componentDefinition,
        pageRef: containerRef,
        optionalIndex: componentIndex + 1,
        ...props,
      });
    }

    if (this.options.dryRun) {
      return;
    }

    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_OLD_COMPONENTS_REMOVAL;
    await Promise.all(
      oldBlogComponents.map(({ componentRef, serialized }) => {
        if (!this.isOldBlogComponent(serialized.data.appPartName)) {
          return Promise.resolve();
        }

        return sdk.components.remove(TOKEN, { componentRef });
      }),
    );
  }

  async removeNewBlogPages() {
    if (this.options.dryRun) {
      return;
    }

    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_NEW_BLOG_PAGES_REMOVAL;
    const pages = [this.newBlogFeedPage];

    if (this.oldBlogPostPage) {
      pages.push(this.newBlogPostPage);
    }

    await Promise.all(
      pages.map(
        (page) =>
          page &&
          this.context.sdk.pages.remove(TOKEN, {
            pageRef: { id: page.id, type: 'DESKTOP' },
            shouldShowEditorRemovePanel: false,
          }),
      ),
    );
  }

  async saveStyleParams() {
    const { sdk } = this.context;

    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_STYLE_PARAMS_MIGRATION;
    const [appInstance, textPresets, colorPresets, postComponentRef] = await Promise.all([
      sdk.document.info.getAppInstance(TOKEN),
      sdk.theme.fonts.getMap(TOKEN).then(getTextPresets),
      sdk.theme.colors.getAll(TOKEN).then(getColorPresets),
      getComponentRef(sdk, POST_WIDGET_ID),
    ]);
    const style = await sdk.tpa.getStyleParams(TOKEN, { compRef: postComponentRef }).then(normalizeColorThemeName);
    await patchSettings(appInstance, postComponentRef.id, 'draft', { textPresets, colorPresets, style });
  }

  async convertOldBlogThankYouPage() {
    this.currentStep = MIGRATION_STEPS.CLIENT_MIGRATION_STYLE_PARAMS_MIGRATION;

    const pages = await this.context.sdk.pages.data.getAll();

    const [thankYouPage] = pages.filter((page) => {
      return page.type.includes('AppPage') && page.appPageId === OLD_BLOG_THANK_YOU_PAGE_ID;
    });

    if (!thankYouPage) {
      return;
    }

    const thankYouPageData = await this.context.sdk.document.pages.serialize(TOKEN, {
      pageRef: thankYouPage,
      maintainIdentifiers: true,
    });
    const pageRef = { id: thankYouPageData.id, type: 'DESKTOP' };

    await this.context.sdk.document.wixapps.convertAppPage(TOKEN, { pageRef });

    const data = {
      ...thankYouPageData.data,
      appPageId: undefined,
      appInnerID: undefined,
      appPageType: undefined,
      type: 'Page',
    };

    await this.context.sdk.pages.data.update(TOKEN, {
      pageRef,
      data,
    });
  }

  // TODO remove when done
  async debugPages() {
    this.pages = await this.context.sdk.pages.data.getAll();
    const serializedPages = await Promise.all(
      this.pages.map((p) =>
        this.context.sdk.document.pages.serialize(TOKEN, {
          pageRef: { id: p.id },
          maintainIdentifiers: false,
        }),
      ),
    );
    console.log(serializedPages);
  }

  async addNewBlogPostWidgetToPostPage(sdk) {
    const newBlogWidgets = await getSerializedComponents({
      sdk,
      predicate: ({ type }) => /(TPAMultiSection)/.test(type),
    });
    const newBlogPostPageWidget = newBlogWidgets.find(
      (widget) => get(widget, 'serialized.data.widgetId') === POST_WIDGET_ID,
    );
    if (!newBlogPostPageWidget) {
      throw new Error('Post page widget is missing');
    }
    await sdk.components.addAndAdjustLayout(TOKEN, {
      componentDefinition: newBlogPostPageWidget.serialized,
      pageRef: this.oldBlogPostPageRef,
    });
  }
}

export default MagicMigration;
