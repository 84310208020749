import monitoring from './monitoring';

const concurentEditing = {
  init,
  withApproval: async (fn) => fn(),
};

function init({ sdk, appToken, isADI }) {
  if (!isADI) {
    concurentEditing.withApproval = createWithApproval({ sdk, appToken });
  }
}

const createWithApproval = ({ sdk, appToken }) => async (fn) => {
  try {
    return await sdk.document.transactions.runAndWaitForApproval(appToken, fn);
  } catch (error) {
    const isTransactionError = await sdk.document.transactions.isConflictError(appToken, error);
    if (isTransactionError) {
      try {
        return await sdk.document.transactions.runAndWaitForApproval(appToken, fn);
      } catch (secondError) {
        monitoring.reportError(secondError);
      }
    } else {
      monitoring.reportError(error);
    }
  }
};

export default concurentEditing;
