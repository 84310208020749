import {
  FEED_PAGE_SECTIONS,
  SECTION_POST_LIST,
  LAYOUT_NAME_PG_GRID,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_NAME_LIST,
  LAYOUT_NAME_PG_CARD_MEDIUM,
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS,
  getLayoutCardsRowTypeParam,
} from '@wix/communities-blog-client-common';
import { BLOG_WIDGET_ID, POST_LIST_WIDGET_ID } from '../../constants/widgets';
import { blogAppDefId } from '../../constants/apps';
import { createUpdateStyleParams } from './create-update-style-params';

const feedSections = FEED_PAGE_SECTIONS;
const postListSection = [SECTION_POST_LIST];
const layoutNames = [
  LAYOUT_NAME_PG_GRID,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_NAME_LIST,
  LAYOUT_NAME_PG_CARD_MEDIUM,
];

export const generateStylesReducer = (acc, section) => {
  return [
    ...acc,
    ...layoutNames.map((layoutName) => {
      return getLayoutCardsRowTypeParam(section, layoutName);
    }),
  ];
};
const feedStyleKeys = feedSections.reduce(generateStylesReducer, []);
const postListStyleKeys = postListSection.reduce(generateStylesReducer, []);

const setFeedCardRowType = createUpdateStyleParams(
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.ITEMS_PER_ROW,
  'number',
  feedStyleKeys,
);
const setPostListCardRowType = createUpdateStyleParams(
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.ITEMS_PER_ROW,
  'number',
  postListStyleKeys,
);

const changeFeedContentHeightType = async ({ sdk, appToken, blogAppComponents }) => {
  const component = blogAppComponents.find(({ widgetId }) => [BLOG_WIDGET_ID].includes(widgetId));
  const compRef = await sdk.document.components.getById(appToken, {
    id: component.id,
  });
  await setFeedCardRowType(sdk, appToken, compRef);
};

const changePostListContentHeightType = async ({ sdk, appToken, blogAppComponents, newWidgetCompRef }) => {
  const component = blogAppComponents.find((comp) => comp.id === newWidgetCompRef.id);
  const newWidgetIsPostList = component?.widgetId === POST_LIST_WIDGET_ID;

  if (!newWidgetIsPostList) {
    return;
  }
  await setPostListCardRowType(sdk, appToken, newWidgetCompRef);
};

export const setDefaultCardsRowType = async ({ sdk, appToken, isClassicEditor }, newWidgetCompRef) => {
  if (!isClassicEditor) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  if (!blogAppData) {
    return;
  }
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);

  if (!newWidgetCompRef) {
    await changeFeedContentHeightType({ sdk, appToken, blogAppComponents });
  } else {
    await changePostListContentHeightType({ sdk, appToken, blogAppComponents, newWidgetCompRef });
  }
};
